import { myPostJson} from '../api.js'
export const GetAITaskConfig = param => myPostJson(`/AITaskConfig/GetAITaskConfig`, param)
export const UpdateAITaskConfig = param => myPostJson(`/AITaskConfig/UpdateAITaskConfig`, param)

export const GetTokenRecharegeConfigList = param => myPostJson(`/RechargeConfig/GetTokenRecharegeConfigList`, param)
export const AddTokenRechargeConfig = param => myPostJson(`/RechargeConfig/AddTokenRechargeConfig`, param)
export const DelTokenRechargeConfig = param => myPostJson(`/RechargeConfig/DelTokenRechargeConfig`, param)

export const GetOpenAIConfig = param => myPostJson(`/AppConfig/GetGptConfig`, param)
export const UpdateOpenAIConfig = param => myPostJson(`/AppConfig/UpdateGptConfig`, param)
