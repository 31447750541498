<template>
    <div class="home">
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane label="任务配置" name="0">
                <el-form :model="taskRuleForm" :rules="rules" ref="taskRuleForm" label-width="160px" class="demo-ruleForm">
                    <div>分享任务</div>
                    <el-form-item label="每日最大分享数" prop="sharedaylimit">
                        <el-input-number v-model="taskRuleForm.sharedaylimit" :min="1" :precision="0" :controls='false'></el-input-number>
                    </el-form-item>
                    <el-form-item label="分享一次可获得奖励" prop="shareaward">
                        <el-input-number v-model="taskRuleForm.shareaward" :min="1" :precision="0" :controls='false'></el-input-number>
                    </el-form-item>

                    <div>邀请任务</div>
                    <el-form-item label="每日最大邀请数" prop="invatedaylimit">
                        <el-input-number v-model="taskRuleForm.invatedaylimit" :min="1" :precision="0" :controls='false'></el-input-number>
                    </el-form-item>
                    <el-form-item label="邀请一次可获得奖励" prop="invateaward">
                        <el-input-number v-model="taskRuleForm.invateaward" :min="1" :precision="0" :controls='false'></el-input-number>
                    </el-form-item>
                </el-form>
                <div style="padding-left: 120px">
                    <el-button v-loading="loadingShow" type="primary" @click="submitForm('taskRuleForm')">保 存</el-button>    
                </div>
            </el-tab-pane>
            <el-tab-pane label="充值配置" name="1">
                <div v-for="(item,index) in configList" :key="index" class="configList">
                    面额{{ item.cost }}兑换{{ item.amount }}<el-button type="text" style="margin-left: 20px" @click="delFn(item)">删除</el-button>
                </div>
                <el-button type="primary" @click="configVisible = true">增加充值价位</el-button>
            </el-tab-pane>
            <el-tab-pane label="chatgpt配置" name="2">
                <el-form
                    :model="ruleForm"
                    :rules="gptRules"
                    ref="ruleForm"
                    label-width="170px"
                    class="demo-ruleForm"
                >
                    <el-form-item label="接口密钥" prop="apikey">
                        <div>
                            <el-input v-model="ruleForm.apikey"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="model" prop="model">
                        <div>
                            <el-select
                            v-model="ruleForm.model"
                            @change="ruleForm.maxtoken = 0"
                            placeholder="请选择model"
                            style="width: 300px"
                            >
                            <el-option
                                v-for="item in modelList"
                                :label="item.name"
                                :value="item.id"
                                :key="item.id"
                            ></el-option>
                            </el-select>
                            <!-- <el-input v-model="ruleForm.model" style="width:300px;"></el-input> -->
                            <el-tooltip
                            class="toolTip"
                            effect="dark"
                            content="OpenAI:使用的AI模型, 价格和智能程度存在不同 text-davinci-003 功能最强大的 GPT-3 模型。可以完成其他模型可以完成的任何任务，通常具有更高的质量、更长的输出和更好的指令遵循。还支持在文本中插入补全。 text-curie-001 非常有能力，但比达芬奇更快，成本更低。 text-babbage-001 能够执行简单的任务，速度非常快，成本更低。 text-ada-001 能够执行非常简单的任务，通常是 GPT-3 系列中最快的型号，而且成本最低。"
                            placement="top"
                            >
                            <img class="icon-tips" src="@/assets/common/tips.png" alt="" />
                            </el-tooltip>
                        </div>
                    </el-form-item>
                    <el-form-item label="maxToken" prop="maxtoken">
                        <div>
                            <el-input-number
                            v-model="ruleForm.maxtoken"
                            :min="0"
                            :precision="2"
                            :controls="false"
                            style="margin-right: 10px"
                            ></el-input-number>
                            <el-tooltip
                            class="toolTip"
                            effect="dark"
                            content="OpenAI:max_tokens 最大回复词数，结合提示若超过model上限则会报错"
                            placement="top"
                            >
                            <img class="icon-tips" src="@/assets/common/tips.png" alt="" />
                            </el-tooltip>
                        </div>
                    </el-form-item>

                    <el-form-item label="isAdvanced" prop="isadvanced">
                        <el-radio-group v-model="ruleForm.isadvanced" class="ml-4">
                            <el-radio :label="1" size="large">是</el-radio>
                            <el-radio :label="0" size="large">否</el-radio>
                        </el-radio-group>
                        <el-tooltip
                            class="toolTip"
                            effect="dark"
                            content="OpenAI:是否关联全文聊天,关联会加剧消耗，但更智能"
                            placement="top"
                        >
                            <img class="icon-tips" src="@/assets/common/tips.png" alt="" />
                        </el-tooltip>
                    </el-form-item>

                    <el-form-item label="maxQt" prop="maxqt">
                        <el-input-number
                            v-model="ruleForm.maxqt"
                            :min="1"
                            :precision="0"
                            :controls="false"
                            style="margin-right: 10px"
                        ></el-input-number>
                        <el-tooltip
                            class="toolTip"
                            effect="dark"
                            content="max quetion token 携带上下文时，取前MaxQt的token的聊天记录，暂时以字符串长度为计算依据，后续优化"
                            placement="top"
                        >
                            <img class="icon-tips" src="@/assets/common/tips.png" alt="" />
                        </el-tooltip>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')"
                            >保存</el-button
                        >
                        <!-- <el-button @click="resetForm('ruleForm')">取消</el-button> -->
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            
        </el-tabs>
        <el-dialog title="新增" :visible.sync="configVisible" @close="closeDialog" width="40%">
            <div>
                <el-form :model="configRuleForm" :rules="rules" ref="configRuleForm" label-width="120px" class="demo-ruleForm">
                    <el-form-item label="面额" prop="cost">
                        <el-input-number v-model="configRuleForm.cost" :min="0.01" :precision="2" :controls='false'></el-input-number>
                    </el-form-item>
                    <el-form-item label="兑换" prop="amount">
                        <el-input-number v-model="configRuleForm.amount" :min="1" :precision="0" :controls='false'></el-input-number>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="configVisible = false">取 消</el-button>
                <el-button v-loading="loadingShow" type="primary" @click="submitConfigForm('configRuleForm',0)">确 定</el-button>
            </span>
        </el-dialog>
       

       
            <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="examineDialog = false">取 消</el-button>
                
            </span> -->
    </div>
</template>
  
<script>
import { GetAITaskConfig, UpdateAITaskConfig, GetTokenRecharegeConfigList, DelTokenRechargeConfig, AddTokenRechargeConfig, GetOpenAIConfig, UpdateOpenAIConfig } from '@/service/operational/operational.js'
import { Message, MessageBox } from 'element-ui'
export default {
    name: 'Home',
    components: {
    },
    data() {
        return {
            loadingShow: false,
            activeName: '0',
            taskRuleForm: {},
            rules: {
                sharedaylimit: [
                    { required: true, message: '请填写每日最大分享数', trigger: 'blur' },
                ],
                shareaward: [
                    { required: true, message: '请填写分享一次可获得奖励', trigger: 'blur' },
                ],
                invatedaylimit: [
                    { required: true, message: '请填写每日最大邀请数', trigger: 'blur' },
                ],
                invateaward: [
                    { required: true, message: '请填写邀请一次可获得奖励', trigger: 'blur' },
                ],
                cost:[
                    { required: true, message: '请填写面额', trigger: 'blur' },
                ],
                amount:[
                    { required: true, message: '请填写兑换数量', trigger: 'blur' },
                ],
            },
            configRuleForm: {},
            configList: [],
            configVisible: false,
            modelList: [
                { name: "gpt-3.5-turbo", id: "gpt-3.5-turbo" },
                { name: "gpt-3.5-turbo-16k", id: "gpt-3.5-turbo-16k" },
                { name: "gpt-4", id: "gpt-4" },
                { name: "gpt-4-32k", id: "gpt-4-32k" },
                { name: "gpt-4-1106-preview", id: "gpt-4-1106-preview" }
            ],
            ruleForm: {},
            gptRules: {
                apiKey: [
                { required: true, message: "请输入接口密钥", trigger: "blur" },
                ],
                // temperature: [
                //   { required: true, message: "请输入temperature", trigger: "blur" },
                // ],
                maxtoken: [
                { required: true, message: "请输入maxToken", trigger: "blur" },
                ],
                // topP: [{ required: true, message: "请输入topP", trigger: "blur" }],
                model: [{ required: true, message: "请选择model", trigger: "change" }],
                isadvanced: [
                { required: true, message: "请选择isAdvanced", trigger: "blur" },
                ],
                maxqt: [{ required: true, message: "请输入maxQt", trigger: "blur" }],
            },
        }
    },
    created() {
        this.GetAITaskConfig()
        this.GetTokenRecharegeConfigList()
        this.GetOpenAIConfig();
    },
    methods: {
        GetOpenAIConfig() {
        // GetOpenAIConfig
        this.loadingShow = true;
        GetOpenAIConfig()
            .then((res) => {
            this.loadingShow = false;
            console.log(res);
            this.ruleForm = res.data;
            })
            .catch((err) => {
            console.log(err);
            this.loadingShow = false;
            });
        },
        closeDialog(){
            this.configRuleForm = {}
        },
        delFn(row) {
            MessageBox.confirm('请确认是否删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loadingShow = true
                DelTokenRechargeConfig({
                    id: row.id,
                }).then(res => {
                    console.log(res)
                    this.loadingShow = false
                    Message.success(`删除成功`)
                    this.GetTokenRecharegeConfigList();
                }).catch(err => {
                    this.loadingShow = false
                    console.log(err)
                })
            }).catch(() => {
                Message.info('取消删除!')
            })
        },
        GetAITaskConfig(){
            this.loadingShow = true
            GetAITaskConfig().then(res => {
                this.loadingShow = false;
                console.log(res)
                this.taskRuleForm = res.data
            }).catch(err => {
                console.log(err)
                this.loadingShow = false;
            })
        },
        GetTokenRecharegeConfigList(){
            this.loadingShow = true
            GetTokenRecharegeConfigList().then(res => {
                this.loadingShow = false;
                console.log(res)
                this.configList = res.data || []
            }).catch(err => {
                console.log(err)
                this.loadingShow = false;
            })
        },
        submitConfigForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) return;
                this.loadingShow = true
                AddTokenRechargeConfig({ ...this.configRuleForm }).then(res => {
                    this.loadingShow = false;
                    this.configVisible = false
                    console.log(res)
                    Message.success(`新增成功`)
                    this.GetTokenRecharegeConfigList()
                }).catch(err => {
                    console.log(err)
                    this.loadingShow = false;
                })

            });
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) return;
                this.loadingShow = true
                if(this.activeName == 0){
                    UpdateAITaskConfig({ ...this.taskRuleForm }).then(res => {
                        this.loadingShow = false;
                        console.log(res)
                        Message.success(`保存成功`)
                        this.GetAITaskConfig()
                    }).catch(err => {
                        console.log(err)
                        this.loadingShow = false;
                    })
                } else {
                    UpdateOpenAIConfig({ ...this.ruleForm })
                    .then((res) => {
                        console.log(res);
                        this.loadingShow = false;
                        Message.success(`保存成功`);
                        this.GetOpenAIConfig();
                    })
                    .catch((err) => {
                        console.log(err);
                        this.loadingShow = false;
                    }); 
                }

            });
        },
        handleClick(){

        },
    }
}
</script>
<style lang="less" scoped>
.icon-tips {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}
.configList{
    margin: 0 20px 20px; 
    font-size: 18px;
}
.posr {
    position: relative;
}

.footer-pagin {
    margin: 20px;
    text-align: right;
}

.t-c {
    text-align: center;
}
.questions-box{
    margin-bottom: 15px;
    padding-right: 30px;
    position: relative;
    .el-icon-delete{
        position: absolute;
        right: -30px;
        font-size: 16px;
        top: 0;
        color: red;
        cursor: pointer;
    }
}
</style>
  
  